import React, { Component } from 'react';
import ProjectCard from './ProjectCard';
import FileCard from './FileCard';
import { Accordion } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './CourseDisplay.css';
import EnvironmentConfiguration from '../config.js';
// var uuid = require("uuid/v4");

var config = EnvironmentConfiguration.getConfig();

export default class CourseDisplay extends Component {
  constructor(props) {
    super(props);

    this.cards = [];

    this.state = {
      courseName: this.props.courseName,
      s3: this.props.s3,
      projects: [],
      filteredProjects: [],
      bucket: this.props.bucket,
      rowLength: this.props.rowLength ? this.props.rowLength : 4,
      pipelineStage: this.props.pipelineStage
    };
  }

  updateListing(callback = null) {
    this.state.s3.listObjectsV2(
      {
        Bucket: this.state.bucket,
        Delimiter: '/',
        Prefix: this.state.courseName
      },
      (err, data) => {
        var projects = [];
        if (err) {
          return;
        } else if (!data) {
          return;
        }

        data.Contents.forEach((file) => {
          projects.push({
            prefix: file.Key,
            type: 'file',
            deploying: false
          });
        });

        data.CommonPrefixes.forEach((projectPrefix) => {
          var deploying = false;
          if (this.state.bucket === config.s3.LIVE) {
            if (
              this.state.pipelineStage === 'LiveDeploy' ||
              this.state.pipelineStage === 'Approval'
            ) {
              if (this.props.fileNeedToBeLive.includes(projectPrefix.Prefix)) {
                deploying = true;
              } else {
                deploying = false;
              }
            } else {
              deploying = false;
            }
          } else {
            deploying = false;
          }

          var project = {
            prefix: projectPrefix.Prefix,
            type: 'project',
            deploying: deploying
          };
          projects.push(project);
        });
        if (callback) {
          this.setState({ projects: projects, filteredProjects: projects }, callback);
          if (this.props.searchText) {
            var filtered = this.state.filteredProjects.filter((proj) =>
              proj.prefix.split('/')[1].toLowerCase().includes(this.props.searchText)
            );
            this.setState({ filteredProjects: filtered });
          }
        } else {
          var filtered = this.state.filteredProjects.filter((proj) =>
            proj.prefix.split('/')[1].toLowerCase().includes(this.props.searchText)
          );
          if (this.props.searchText) {
            this.setState({ filteredProjects: filtered });
          } else {
            this.setState({ filteredProjects: projects });
          }
        }
      }
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.projects !== this.state.projects) {
      return false;
    } else {
      return true;
    }
  }

  componentDidMount() {
    this.updateListing(this.forceUpdate);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.projects !== this.state.projects) {
      this.updateListing();
    }
    if (nextProps.searchText !== this.props.searchText) {
      this.updateListing();
    }
  }

  render() {
    return (
      <>
        {this.state.filteredProjects.length > 0 ? (
          <Accordion>
            <Accordion.Item eventKey="0" style={{ border: '0px' }}>
              <Accordion.Header>
                <h4>
                  <i>{this.state.courseName}</i>
                </h4>
              </Accordion.Header>
              <Accordion.Body isOpen={true} style={{ paddingLeft: '0px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'start'
                  }}>
                  {this.state.filteredProjects.map((proj) => (
                    <div
                      style={{
                        paddingLeft: '2px',
                        paddingRight: '2px',
                        height: 'inherit',
                        width: '20%'
                      }}
                      key={'root-files-' + proj.prefix}>
                      {proj.type === 'file' ? (
                        <FileCard file={proj.prefix} bucket={this.state.bucket} />
                      ) : (
                        <ProjectCard
                          setModalOpen={this.props.setModalOpen}
                          s3={this.state.s3}
                          project={proj.prefix}
                          bucket={this.state.bucket}
                          deploying={proj.deploying}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ) : (
          <div></div>
        )}
      </>
    );
  }
}

CourseDisplay.propTypes = {
  courseName: PropTypes.string,
  s3: PropTypes.object,
  bucket: PropTypes.object,
  rowLength: PropTypes.number,
  pipelineStage: PropTypes.object,
  fileNeedToBeLive: PropTypes.object
};
