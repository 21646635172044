import React, { Component } from 'react';
import './Library.css';
import environmentConfiguration from '../config.js';
import CourseDisplay from './CourseDisplay';
import FileCard from './FileCard';
import SearchBar from './SearchBar';
import { Row, Col, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import { Accordion } from 'react-bootstrap';
import PropTypes from 'prop-types';

var config = environmentConfiguration.getConfig();
const AWS = require('aws-sdk');
var auth = null;
var s3;

export default class Library extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.loading = false;

    this.state = {
      credentials: {},
      courses: [],
      rootFiles: [],
      filteredRootFiles: [],
      filteredProjectsCount: 0,
      selectedProject: '',
      bucket: config.s3.LIVE,
      time: new Date().getTime(),
      pipelineStage: this.props.pipelineStage,
      searchValue: '',
      isAllExpanded: true
    };
  }

  updateCourses() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    s3.listObjectsV2({ Bucket: this.state.bucket, Delimiter: '/' }, (err, data) => {
      if (data) {
        this.loadCourses(data, this._isMounted);
      }
      this.loading = false;
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    auth = this.props.auth;

    auth.currentCredentials().then((credentials) => {
      s3 = new AWS.S3({
        credentials: credentials
      });

      this.updateCourses();
    });
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.pipelineStage !== this.state.pipelineStage) {
      this.setState({ pipelineStage: nextProps.pipelineStage });
    }
    this.updateCourses();
  }

  loadCourses = (data, _isMounted) => {
    if (_isMounted) {
      var courses = [];
      var rootFiles = [];
      if (data.Contents) {
        data.Contents.forEach((file) => {
          rootFiles.push(file.Key);
        });
      }

      if (!data.CommonPrefixes) {
        return;
      }
      data.CommonPrefixes.forEach((coursePrefix) => {
        var courseName = coursePrefix.Prefix;
        courses.push(courseName);
      });
      this.props.coursesLoaded(courses);
      this.setState({ courses: courses, rootFiles: rootFiles, filteredRootFiles: rootFiles });
      if (this.state.searchValue) {
        var filtered = this.state.filteredRootFiles.filter((name) =>
          name.toLowerCase().includes(this.state.searchValue)
        );
        this.setState({ filteredRootFiles: filtered });
      }
    }
  };

  //Search
  handleSearch = (event) => {
    this.setState({ searchValue: event.target.value.toLowerCase() });
  };

  renderRootFilesGrid() {
    var grid = [];
    grid.push(
      <Accordion>
        <Accordion.Item eventKey="0" style={{ border: '0px' }}>
          <Accordion.Header>
            <h4>
              <i>{'root/'}</i>
            </h4>
          </Accordion.Header>
          <Accordion.Body isOpen={true} style={{ paddingLeft: '0px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'start'
              }}>
              {this.state.filteredRootFiles.map((file) => (
                <div
                  style={{
                    paddingLeft: '2px',
                    paddingRight: '2px',
                    height: 'inherit',
                    width: '20%'
                  }}
                  key={'root-files-' + file}>
                  <FileCard file={file} bucket={this.state.bucket} />
                </div>
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
    return grid;
  }

  renderCardList = (start, amount) => {
    var grid = [];
    if (this.state.filteredRootFiles.length > 0) {
      grid = this.renderRootFilesGrid();
    }

    // Sort courses alphabetically
    const sortedCourses = this.state.courses.slice().sort((a, b) => a.localeCompare(b));

    for (var i = 0; i < sortedCourses.length; i++) {
      var deployingFiles = [];
      for (var fi = 0; fi < this.props.fileNeedTobeLive.length; fi++) {
        var deployingFile = this.props.fileNeedTobeLive[fi];
        if (deployingFile.split('/')[0] + '/' === sortedCourses[i]) {
          deployingFiles.push(deployingFile);
        }
      }
      grid.push(
        <CourseDisplay
          setModalOpen={this.props.setModalOpen}
          courseName={sortedCourses[i]}
          s3={s3}
          key={'course-' + sortedCourses[i]}
          bucket={this.state.bucket}
          rowLength={amount}
          fileNeedToBeLive={deployingFiles}
          pipelineStage={this.state.pipelineStage}
          searchText={this.state.searchValue}
        />
      );
    }
    return grid;
  };

  render() {
    if (this.state.loading) {
      return <React.Fragment></React.Fragment>;
    }
    return (
      <Container className="library">
        <Row style={{ 'margin-top': '20px', 'margin-bottom': '20px' }}>
          <SearchBar searchFunc={this.handleSearch} />
        </Row>
        {this.renderCardList(0, 4)}
      </Container>
    );
  }
}

Library.propTypes = {
  pipelineStage: PropTypes.object,
  auth: PropTypes.object,
  coursesLoaded: PropTypes.object,
  fileNeedTobeLive: PropTypes.object
};
